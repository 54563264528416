import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CustomButton from './CustomButton';
import Loading from './Loading';
import axiosInstance from '../../main/utils/axios/axiosInstance';
import { __env } from '../../envloader';

import {
  AppDetailsContainer,
  AppName,
  AppShortDesc,
  AppButtonContainer,
  LeftButtons,
  RightButtons
} from '../styles/styled-components/AppPage';
import {
  PathTreeTitle,
  PathTreeContainer,
  PathTreeImage
} from '../styles/styled-components/PathDescription';

import { ENPath, PLPath } from '../../assets/svg/path';

const PathApp = ({ appName, setIsApp, nextStep }) => {

  const { t, i18n } = useTranslation();

  const [ appData, setAppData ] = useState({});
  const [ pathTreeChoosenImg, setPathTreeChoosenImg ] = useState(null);

  const ChoosePathTree = useCallback(() => {
    let result = null;

    if (i18n.resolvedLanguage === "en") {
      result = ENPath[appName];
    }
    else {
      result = PLPath[appName];
    }

    setPathTreeChoosenImg(result);
  }, [ appName, i18n.resolvedLanguage ]);

  useEffect(() => {
    axiosInstance.get(`${__env.SHINY_PROXY_DOMAIN}api/proxyspec/${appName}`)
      .then(({ data: { data } } ) => setAppData(data));
    ChoosePathTree();
  }, [ appName, ChoosePathTree ]);

  return (
    <>
      <AppDetailsContainer>
        {
          appData?.displayName ?
            <>
              <AppName>{t(`apps.titles.${appName}`)}</AppName>
              <AppShortDesc></AppShortDesc>
              <p>{t(`apps.descriptions.${appName}`)}</p>
              <AppButtonContainer>
                <LeftButtons />
                <RightButtons>
                  <CustomButton text={t('path.skip')} action={nextStep} />
                  <CustomButton text={t('path.start')} action={() => setIsApp(true)} main />
                </RightButtons>
              </AppButtonContainer>
              <PathTreeTitle>{t('path.tree')}</PathTreeTitle>
              <PathTreeContainer>
                <PathTreeImage src={pathTreeChoosenImg} alt="path tree" />
              </PathTreeContainer>
            </>
            :
            <Loading />
        }
      </AppDetailsContainer>
    </>
  );
};

PathApp.propTypes = {
  appName: PropTypes.string,
  setIsApp: PropTypes.func,
  nextStep: PropTypes.func
};

export default PathApp;