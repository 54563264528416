import React from 'react';
import PropTypes from 'prop-types';

import {
  RadioLabel,
  RadioInput,
  RadioCircle,
  RadioLabelText
} from '../styles/styled-components/CustomRadioButton';

const CustomRadioButton = ({ name, value, checked, onChange, label }) => {
  return (
    <RadioLabel>
      <RadioInput
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <RadioCircle></RadioCircle>
      {label && <RadioLabelText className="custom-radio-button-label">{label}</RadioLabelText>}
    </RadioLabel>
  );
};

CustomRadioButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string
};

export default CustomRadioButton;