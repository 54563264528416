import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import Callback from './callback';
import LogoutCallback from './logoutCallback';
import { loginCallbackPath, logoutCallbackPath } from '../utils/authUtils';
import LoginToAccess from '../components/loginToAccess';
import NotFound from '../components/notFound';
import Loading from '../components/loading';

import TopBar from '../../portal/containers/Topbar';
import Main from '../../portal/containers/Main';
import AppPage from '../../portal/containers/AppPage';
import PathPage from '../../portal/containers/PathPage';
import LandingPage from '../../portal/containers/LandingPage';
import '../../portal/styles/index.scss';

@connect(
  state => ({
    loginStore: state.login.get('loginData'),
    loginFinishingInProgress: state.login.get('login_finishing_in_progress'),
  })
)
export default class Root extends Component {
  renderContent = () => {

    if (this.props.loginFinishingInProgress) {
      //login finishing is in progress
      return <Loading className="text-center mt-5" />;
    }

    if (!this.props.loginStore) {
      //User not logged in
      return (
        <Switch>
          <Route exact strict path='/' component={LandingPage} />
          <Route path={ loginCallbackPath } exact component={Callback} />
          <Route path={ logoutCallbackPath } exact component={LogoutCallback} />
          <Route exact strict path='/app/:appId' component={LoginToAccess} />
          <Route exact strict path='/path/:pathId' component={LoginToAccess} />
          <Route component={NotFound} />
        </Switch>
      );
    }
    else {
      //User logged in
      return (
        <Switch>
          <Route path={loginCallbackPath} exact render={() => <Redirect to='/'/>}/>
          <Route path={logoutCallbackPath} exact render={() => <Redirect to='/'/>}/>
          <Route exact strict path='/' component={Main} />
          <Route exact strict path='/app/:appId' component={AppPage} />
          <Route exact strict path='/path/:pathId/:currentStep' component={PathPage} />
          <Route component={NotFound} />
        </Switch>
      );
    }
  };

  render() {
    return (
      <div>
        <TopBar loggedIn={this.props.loginStore} />
        <Container fluid className="p-0">
          <div className="main-content">
            {this.renderContent()}
          </div>
        </Container>
      </div>
    );
  }
}

Root.propTypes = {
  loginStore: PropTypes.object, //HOC
  loginFinishingInProgress: PropTypes.bool, //HOC
};
