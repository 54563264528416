import styled from 'styled-components';

export const ContactContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 48px 48px 0px 0px;
  box-shadow: 0px 0px 1px rgba(51, 51, 51, 0.4), 0px 0.5px 4px rgba(51, 51, 51, 0.1);
  padding: 0 12%;
  padding-top: 80px;
  padding-bottom: 64px;
  
`;

export const ContactTitle = styled.p`
  color: ${({ theme }) => theme.colors.green7 };
  font-weight: ${({ theme }) => theme.fontWeights.bold };
`;

export const MailLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray7};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const SocialIcon = styled.div`
  background-color: ${({ theme }) => theme.colors.gray6 };
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContactFormContainer = styled.div`
  max-width: 550px;
`;

export const ContactDot = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.green6 };
  margin-left: auto;
  margin-bottom: 1rem;
`;

export const ContactInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.gray3 };
  border-radius: 4px;
  padding: 10px 12px;
  width: 48%;
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

export const ContactTextArea = styled.textarea`
  width: 100%;
  height: 150px;
  margin: 1rem 0;
  border: 1px solid ${({ theme }) => theme.colors.gray3 };
  border-radius: 4px;
  padding: 10px 12px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

export const ContactSubmit = styled.button`
  float: right;
  border: none;
  background-color: ${({ theme }) => theme.colors.grayBlue };
  color: ${({ theme }) => theme.colors.white };
  border-radius: 38px;
  padding: 10px 64px;
`;

export const FooterLogos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 12%;
`;

export const FooterInfo = styled.p`
  text-align: center;
  padding: 1rem 20%;
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const FooterAdditional = styled.div`
  background-color: ${({ theme }) => theme.colors.grayBlue};
  display: flex;
  justify-content: space-between;
  padding: 1rem 10%;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
`;

export const PrivacyPolicy = styled.a`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
`;