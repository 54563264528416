import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Login from '@main/containers/login';
import { NavbarLogo, NavTopBar, TopbarNav, TopbarContainer, LanguageButton, LanguageButtonsContainer } from '../styles/styled-components/Topbar';

@withTranslation()
@connect(
  (state) => ({
    appIsRunning: state.applicationsReducer.get("appIsRunning")
  })
)
export default class TopBar extends Component {

  render() {
    const { t, i18n } = this.props;

    return (
      <TopbarContainer className="p-0">
        <TopbarNav color="faded" expand>
          <NavbarLogo to="/">{t('name')}</NavbarLogo>
          <Collapse navbar>
            <NavTopBar navbar pills>
              <Route strict path='/' render={props => (
                <Login location={props.location} />
              )} />
            </NavTopBar>
          </Collapse>
          <LanguageButtonsContainer disabled={!!this.props.appIsRunning}>
            <LanguageButton 
              onClick={() => i18n.changeLanguage("en")}
              active={i18n.resolvedLanguage === "en" ? 1 : 0}
              disabled={!!this.props.appIsRunning}
            >
              EN
            </LanguageButton>
            <LanguageButton 
              onClick={() => i18n.changeLanguage("pl")}
              active={i18n.resolvedLanguage === "pl" ? 1 : 0}
              disabled={!!this.props.appIsRunning}
            >
              PL
            </LanguageButton>
          </LanguageButtonsContainer>
        </TopbarNav>
      </TopbarContainer>
    );
  }
}

TopBar.propTypes = {
  t: PropTypes.func, //HOC
  appIsRunning: PropTypes.bool,
  i18n: PropTypes.shape({
    changeLanguage: PropTypes.func,
    resolvedLanguage: PropTypes.func
  })
};
