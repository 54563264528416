import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import axiosInstance from '../../main/utils/axios/axiosInstance';
import { __env } from '../../envloader';
import {
  APPLICATION_IS_RUNNING
} from '../actions/applicationsActions';

import Loading from './Loading';
import { IframeApp } from '../styles/styled-components/SingleApp';

const SingleApp = ({ pathAppName, setProxyAppId, setIsAppShow }) => {

  const { appId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedAppId = pathAppName || appId;

  const username = useSelector(state => state.login.get("loginData").get("username"));

  const [ proxyId, setProxyId ] = useState("");
  const [ iFrameSrc, setIFramSec ] = useState();
  const [ refreshTime, setRefreshTime ] = useState(500);
  const [ showApp, setShowApp ] = useState(0);

  // Check if proxy exist
  useEffect(() => {
    axiosInstance.get(`${__env.SHINY_PROXY_DOMAIN}api/proxy/`).then(({ data }) => {
      const alreadyExistedApp = data.data.find(proxy => proxy.specId === selectedAppId);
      setProxyId(alreadyExistedApp ? alreadyExistedApp.id : "none");
      setRefreshTime(10000);
    });
  }, [ selectedAppId ]);

  // Init
  useEffect(() => {

    const turningOnCheck = () => {
      if (proxyId) {
        // Get status of app (if dosen't exist, proxyId variable equals undefined. That makes it returning status: "Stopped")
        axiosInstance.get(`${__env.SHINY_PROXY_DOMAIN}api/${proxyId}/status`).then(({ data: { data } }) => {
          if (data.status === 'Stopped') {
            // If process dosen't exist, post to start process
            axiosInstance.post(`${__env.SHINY_PROXY_DOMAIN}app_i/${selectedAppId}/${selectedAppId}_${username.replace("@", "_")}`)
              .then(response => {
                setProxyId(response.data.data.id);
              })
              .catch(err => null);
          }
          else if (data.status === 'Up') {
            if (!iFrameSrc) {
              // Set iframe src to main direction where it's going to get authorized
              setIFramSec(`${__env.SHINY_PROXY_DOMAIN}app_direct/${proxyId}/`); 
              setTimeout(() => {
                // Set iframe src to app direction when it's already authorized
                setIFramSec(`${__env.SHINY_PROXY_DOMAIN}app_direct_i/${selectedAppId}/${selectedAppId}_${username.replace("@", "_")}`);
                setTimeout(() => {
                  setShowApp(1);
                }, 2000);
              }, 3000);
            }
            else {
              // After every 10s post for keeping the app alive
              axiosInstance.post(`${__env.SHINY_PROXY_DOMAIN}heartbeat/${proxyId}`);
            }
          }
        });
      }
    };

    if (proxyId !== "temp") {
      const interval = setInterval(() => turningOnCheck(), refreshTime);
      return () => clearInterval(interval);
    }
    
    turningOnCheck();

  }, [ proxyId, iFrameSrc, selectedAppId, refreshTime, username, setProxyId ]);

  useEffect(() => {
    dispatch({ type: APPLICATION_IS_RUNNING, payload: !!showApp });

    return () => {
      dispatch({ type: APPLICATION_IS_RUNNING, payload: false });
    };
  }, [ showApp, dispatch ]);

  // Set proxy app id for next step function
  useEffect(() => {
    if (proxyId !== "" && setProxyAppId) setProxyAppId(proxyId);
  }, [ proxyId, setProxyAppId ]);

  // Set isAppShow for next step button in PathTopBar
  useEffect(() => {
    if (setIsAppShow) setIsAppShow(!!showApp);
  }, [ setIsAppShow, showApp ]);

  return (
    <div>
      {
        iFrameSrc &&
          <IframeApp 
            src={iFrameSrc} 
            display={showApp}
            isPath={pathAppName ? 1 : 0}
          />
      }
      {
        !showApp &&
          (
            <Loading text={t('launching_app')} background />
          )
      }
    </div>
  );
};

SingleApp.propTypes = {
  pathAppName: PropTypes.string,
  setProxyAppId: PropTypes.func,
  setIsAppShow: PropTypes.func
};

export default SingleApp;