import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PathTopBarContainer = styled.div`
  height: 44px;
  width: ${({ app }) => app ? "100" : "50"}%;
  padding: ${({ app }) => app ? "0 2rem" : "0"};
  margin: ${({ app }) => app ? "0" : "0 auto"};
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ app }) => app ? "1" : "4"}rem;
`;

export const BackButton = styled(Link)`
  display: flex;
  align-items: center;
`;

export const BackButtonText = styled.p`
  margin: 0;
  margin-left: 1rem;
  color: ${({ theme }) => theme.colors.gray7};
`;