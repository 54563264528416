import React from 'react';
import PropTypes from 'prop-types';

import { CustomAppButton } from '../styles/styled-components/CustomButton';

const CustomButton = ({ action, text, main, disabled }) => {
  return (
    <CustomAppButton onClick={action} main={main ? 1 : 0} disabled={disabled}>{text}</CustomAppButton>
  );
};

CustomButton.propTypes = {
  action: PropTypes.func,
  text: PropTypes.string,
  main: PropTypes.bool,
  disabled: PropTypes.number
};

export default CustomButton;