import anovaOneWay from './anova_oneway.svg';
import anovaTwoWay from './anova_twoway.svg';
import anovaThreeWay from './anova_threeway.svg';
import chiTestSquared from './chi_test_squared.svg';
import kruskal from './kruskal.svg';
import linearRegresion from './linear_regression.svg';
import normality from './normality.svg';
import oneSummary from './one_summary.svg';
import outliners from './outliers.svg';
import pca from './pca.svg';
import preProcessing from './preprocessing.svg';
import tTest from './t_test.svg';
import wilcoxon from './wilcoxon.svg';

const ENPath = {
  anova_oneway: anovaOneWay,
  anova_twoway: anovaTwoWay,
  anova_threeway: anovaThreeWay,
  chi_test_squared: chiTestSquared,
  kruskal: kruskal,
  linear_regression: linearRegresion,
  normality: normality,
  one_summary: oneSummary,
  outliers: outliners,
  pca: pca,
  preprocessing: preProcessing,
  t_test: tTest,
  wilcoxon: wilcoxon
};

export default ENPath;