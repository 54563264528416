import styled from 'styled-components';

import { BiDownArrowAlt } from 'react-icons/bi';
import FunctionsBackground from '../../../assets/svg/landing/FunctionsBackground.svg';

export const MainSection = styled.div`
  position: relative;
  height: 700px;
  width: 100%;
  margin-bottom: 110px;
`;

export const AnimationContainer = styled.video`
  width: 100vw;
  height: 700px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
`;

export const ContentContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
`;

export const Title = styled.h1`
  padding-top: 4rem;
  color: ${({ theme }) => theme.colors.grayBlue };
  font-family: "Merriweather";
  font-size: ${({ theme }) => theme.fontSizes.xl5 };
  font-weight: ${({ theme }) => theme.fontWeights.bold };
  max-width: 800px;
`;

export const GreenText = styled.span`
  color: ${({ theme }) => theme.colors.green6 };
  font-family: "Merriweather";
`;

export const SeeMoreButton = styled.button`
  all: unset;
  margin: 54px auto 68px auto;
  background-color: ${({ theme }) => theme.colors.grayBlue };
  padding: 7px 24px 10px 24px;
  border-radius: 38px;
  font-size: 24px;
  display: flex;
  align-items: center;
`;

export const SeeMoreText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.white};
  margin-left: 16px;
  margin-top: 3px;
`;

export const ChevronIcon = styled(BiDownArrowAlt)`
  color: ${({ theme }) => theme.colors.white};
`;

export const DescriptionTextContainer = styled.div`
  max-width: 680px;
`;

export const DescriptionMainText = styled.h3`
  font-size: 25px;
  font-family: "Merriweather";
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 150%;
  letter-spacing: 0.125px; 
  margin-bottom: 36px;
`;

export const DescriptionSubText = styled.p`
  color: ${({ theme }) => theme.colors.gray6};
  line-height: 164%;
  letter-spacing: 0.08px; 
`;

export const SubTitle = styled.h3`
  color: ${({ theme }) => theme.colors.grayBlue};
  font-size: ${({ theme }) => theme.fontSizes.xl3};
  font-family: "Merriweather";
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-top: 98px;
  margin-bottom: 24px;
  line-height: 150%;
  letter-spacing: 0.14px;
`;

export const SubText = styled.p`
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fontSizes.xl};
  line-height: 129%;
`;

export const LandingPath = styled.div`
  padding: 1.5rem 2rem;
  background-color: ${({ theme }) => theme.colors.white };
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2rem;
  min-width: 260px;
  border-radius: 24px;
  box-shadow: 0px 0.5px 8px 0px rgba(51, 51, 51, 0.10); 
`;

export const LandingPathTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.xl };
  &::first-letter {
    text-transform: capitalize;
  }
`;

export const LandingPathImg = styled.img`
  max-height: 4rem;
  margin: 20px 0;
`;

export const FunctionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${FunctionsBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 10% 10% 10%; 
`;