import styled from 'styled-components';

export const PathTreeTitle = styled.p`
  font-family: 'Merriweather';
  font-size: ${({ theme }) => theme.fontSizes.xl2};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-top: 3rem;
`;

export const PathTreeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

export const PathTreeImage = styled.img`
  max-width: 48vw;
`;