import styled from 'styled-components';

export const CustomAppButton = styled.button`
  border-radius: 38px;
  padding: 10px 40px;
  border: ${({ theme, main }) => main ? "none" : '1px solid ' + theme.colors.gray2};
  background-color: ${({ theme, main }) => main ? theme.colors.green3 : theme.colors.white};
  color: ${({ theme }) => theme.colors.grayBlue};
  margin-left: ${({ main }) => main ? "auto" : "0"};
  filter: grayscale(${({ disabled }) => disabled ? 1 : 0});
`;
