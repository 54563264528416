import React, { useRef, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import LandingFunctionCards from '../components/LandingFunctionCards';
import Footer from '../components/Footer';
import {
  MainSection,
  AnimationContainer, 
  ContentContainer,
  Title,
  GreenText,
  SeeMoreButton,
  SeeMoreText,
  ChevronIcon,
  DescriptionTextContainer,
  DescriptionMainText,
  DescriptionSubText,
  LandingPath,
  LandingPathTitle,
  LandingPathImg,
  SubTitle,
  SubText,
  FunctionsContainer
} from '../styles/styled-components/LandingPage';

import backgroundVideo from '../../assets/video/NEBI_Shiny.mp4';
import descriptionIllustration from '../../assets/svg/landing/DescriptionIllustration.svg';
import transcriptomicsIcon from '../../assets/svg/icons/transcriptomics.svg';
import proteomicsIcon from '../../assets/svg/icons/proteomics.svg';
import metabolomicsIcon from '../../assets/svg/icons/metabolomics.svg';
import pathDefaultPL from '../../assets/svg/path/pathDefaultPL.svg';
import pathDefaultEN from '../../assets/svg/path/pathDefaultEN.svg';

const LandingPage = () => {

  const { i18n, t } = useTranslation();

  const [ pathTreeImg, setPathTreeImg ] = useState(pathDefaultPL);
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  const landingPaths = [
    [ "transcriptomics", transcriptomicsIcon ],
    [ "proteomics", proteomicsIcon ], 
    [ "metabolomics", metabolomicsIcon ]
  ];

  useEffect(() => {

    setPathTreeImg(i18n.resolvedLanguage === "en" ? pathDefaultEN : pathDefaultPL);

  }, [ i18n.resolvedLanguage ]);

  return (
    <>
      <MainSection>
        <AnimationContainer src={backgroundVideo} autoPlay loop muted />
        <ContentContainer>
          <Title>
            <Trans i18nKey="landing.title">
              National <GreenText>Integration</GreenText><br/> NEBI
            </Trans>
          </Title>
          <SeeMoreButton onClick={executeScroll}>
            <ChevronIcon />
            <SeeMoreText>{t('landing.find_out_more')}</SeeMoreText>
          </SeeMoreButton>
        </ContentContainer> 
      </MainSection>
      <div className="d-flex justify-content-around align-items-center mb-5" ref={myRef}>
        <DescriptionTextContainer>
          <DescriptionMainText>
            <Trans i18nKey="landing.description.main">
              Portal <GreenText>Multiomic Stats</GreenText> dedicated
            </Trans>
          </DescriptionMainText>
          <DescriptionSubText>
            {t('landing.description.sub')}
          </DescriptionSubText>
        </DescriptionTextContainer>
        <img src={descriptionIllustration} alt="" />
      </div>
      <hr />
      <div className="d-flex flex-column justify-content-around align-items-center px-5">
        <SubTitle>{t('landing.fields.title')}</SubTitle>
        <SubText>{t('landing.fields.subtitle')}</SubText>
        <div className="d-flex justify-content-between w-75 my-5">
          {
            landingPaths.map(([ key, val ]) => (
              <LandingPath key={key}>
                <LandingPathTitle>{t(`main.paths.${key}`)}</LandingPathTitle>
                <LandingPathImg src={val} />
              </LandingPath>
            ))
          }
        </div>
      </div>
      <FunctionsContainer>
        <div className="w-75">
          <SubTitle>{t('landing.test_functions.title')}</SubTitle>
          <SubText>
            <Trans i18nKey="landing.test_functions.subtitle">
              portal provides <b>Multiomic Stats</b> offers:
            </Trans>
          </SubText>
        </div>
        <LandingFunctionCards />
      </FunctionsContainer>
      <div className="d-flex flex-column justify-content-around align-items-center mb-5">
        <SubTitle>{t('landing.path_title')}</SubTitle>
        <img src={pathTreeImg} alt="path" className="py-5" />
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;