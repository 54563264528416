import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';

import { 
  AppContainer,
  AppBasicContainer,
  AppMoreInfoButton,
  AppCollapseContainer,
  AppLink,
  AppMoreInfoIcon
} from '../styles/styled-components/AppButton';

const AppButton = ({ id }) => {
 
  const { t } = useTranslation();

  const [ isMoreInfoOpen, setIsMoreInfoOpen ] = useState(false);
  
  return (
    <AppContainer>
      <AppBasicContainer>
        <AppLink to={`/app/${id}`}>
          <span>{t(`apps.titles.${id}`)}</span>
        </AppLink>
        <AppMoreInfoButton onClick={() => setIsMoreInfoOpen(!isMoreInfoOpen)}>
          <AppMoreInfoIcon rotate={isMoreInfoOpen ? 1 : 0} />
        </AppMoreInfoButton>
      </AppBasicContainer>
      <Collapse isOpen={isMoreInfoOpen}>
        <AppCollapseContainer>
          {t(`apps.short_description.${id}`)}
        </AppCollapseContainer>
      </Collapse>
    </AppContainer>
  );
};

AppButton.propTypes = {
  id: PropTypes.string
};

export default AppButton;