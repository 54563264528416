import axios from 'axios';
import IdTokenVerifier from 'idtoken-verifier';
import qs from 'qs';

import * as notify from '../main/utils/notify';
import i18n from '../main/utils/i18nConfigProvider';
import { logInSuccess, logInFail } from './actions/login';
import { __env } from '../envloader';
import { getAuthConfig, logoutCallbackPath } from '../main/utils/authUtils';
import { getSS, setSS } from '../main/utils/sessionStorageProvider';
import { SessionCheck } from './sessionCheck';
import { LOG_IN, LOG_IN_FINISH, LOG_OUT } from './actions/login.js';
import { getRedirectUri, logInRedirect, verifyAccessToken } from './utils/loginUtils';
import { getLogger } from '../main/utils/logger';

export const getOidcMiddleware = (axiosInstance) => {
  if (!axiosInstance) {
    axiosInstance = axios.create({
      timeout: __env.AUTH_TIMEOUT
    });
  }

  return store => next => action => {
    switch (action.type) {

    case LOG_IN:
      logInRedirect(action.payload.prompt);
      break;

    case LOG_IN_FINISH:
      const redirect_uri = getRedirectUri();
      const client_id = __env.AUTH_CLIENT_ID;
      const client_secret = __env.AUTH_CLIENT_SECRET;

      if (action.payload.data.state !== getSS('state', true)) {
        store.dispatch(logInFail());
        throw Error("Incompatible state");
      }
      const session_state = action.payload.data.session_state;
      const data = {
        grant_type: 'authorization_code',
        code: action.payload.data.code,
        client_id: client_id,
        redirect_uri: redirect_uri,
        code_verifier: getSS('code_verifier', true)
      };
      //if client_secret is empty, null or undefined it wont be sent
      if (client_secret && 0 !== client_secret.length) data.client_secret = client_secret;

      getAuthConfig().then((config) => {
        axiosInstance.post(config.AUTH_TOKEN_ENDPOINT, qs.stringify(data),
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
          .then(function (response) {
            const verifier = new IdTokenVerifier({
              issuer: config.AUTH_ISSUER,
              audience: client_id,
              jwksURI: config.AUTH_JWKS_URI,
            });
            if (__env.TEST_MODE && __env.TEST_MODE === "true") {
              const idTokenData = verifier.decode(response.data.id_token);
              const data = {
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token,
                username: idTokenData.payload[__env.AUTH_TOKEN_USERNAME_FIELD_NAME]
              };
              store.dispatch(logInSuccess({ data }));
            }
            else {
              verifier.verify(response.data.id_token, null, (error, payload) => {
                if (error) {
                  notify.error(i18n.t('common:authentication_error_notification_title'), error.message);
                  store.dispatch(logInFail());
                }
                else if (payload) {
                  verifyAccessToken(response.data.access_token);
                  const data = {
                    accessToken: response.data.access_token,
                    refreshToken: response.data.refresh_token,
                    username: payload[__env.AUTH_TOKEN_USERNAME_FIELD_NAME]
                  };
                  store.dispatch(logInSuccess({ data }));

                  if (__env.AUTH_CHECK_SESSION)
                    new SessionCheck(client_id, session_state, config.AUTH_CHECK_SESSION_IFRAME,store);
                }
              });
            }
          }).catch(function(error) {
            if (error.code && error.code === 'ECONNABORTED') {
              handleAuthTimeoutError(error);
            }
            else {
              notify.error(i18n.t('common:authentication_error_notification_title'), error.message);
            }
            store.dispatch(logInFail());
          });
      }).catch(function (error) {
        notify.error(i18n.t('common:authentication_error_notification_title'), error.message);
        store.dispatch(logInFail());
        throw error;
      });
      break;

    case LOG_OUT:
      //Call idp end session endpoint and return to main page
      const urlArray = window.location.href.split("/");
      const logoutParams = {
        post_logout_redirect_uri: urlArray[0] + "//" + urlArray[2] + __env.PUBLIC_URL + logoutCallbackPath,
      };
      setSS('logout_in_progress', true);
      getAuthConfig().then((config) => {
        window.location.assign(config.AUTH_END_SESSION_ENDPOINT + "?" + qs.stringify(logoutParams));
      });
      break;

    default:
      break;
    }

    next(action);
  };

};

const handleAuthTimeoutError = (error) => {
  const logger = getLogger({ loggerName: 'authTimeout' });
  logger.error(error);
  notify.error(
    i18n.t('common:auth_timeout_error_notification_title'),
    i18n.t('common:auth_timeout_error_notification_body')
  );
};