import React from 'react';
import PropTypes from 'prop-types';
import { BsTwitter, BsInstagram, BsLinkedin } from 'react-icons/bs';
import { useTranslation, Trans } from 'react-i18next';

import {
  ContactContainer,
  ContactTitle,
  MailLink,
  SocialIcon,
  ContactFormContainer,
  ContactDot,
  ContactInput,
  ContactTextArea,
  ContactSubmit,
  FooterLogos,
  FooterInfo,
  FooterAdditional,
  PrivacyPolicy,
} from '../styles/styled-components/Footer';

import EUFundsLogo from '../../assets/svg/logos/EUFunds.svg';
import RPLogo from '../../assets/svg/logos/RP.svg';
import EULogo from '../../assets/svg/logos/EU.svg';

const Footer = ({ contact }) => {
  const { t } = useTranslation();

  return (
    <>
      {contact && (
        <ContactContainer>
          <div>
            <ContactTitle>{t("footer.contact")}</ContactTitle>
            <p className="mb-3">
              <Trans i18nKey="footer.write_to_us">
                Write to us{" "}
                <MailLink href="mailto:info@nebi.com">info@nebi.com</MailLink>{" "}
                contact form
              </Trans>
            </p>
            <div>
              <b>{t("name")}</b>
              <p className="mb-0">ul. Kwiatowa 12</p>
              <p className="mb-5">60-679 Poznań, Poland</p>
            </div>
            <div>
              <p>{t("footer.find_us")}</p>
              <div className="d-flex">
                <SocialIcon className="mr-4">
                  <BsTwitter color="#FFF" size={18} />
                </SocialIcon>
                <SocialIcon className="mr-4">
                  <BsInstagram color="#FFF" size={18} />
                </SocialIcon>
                <SocialIcon>
                  <BsLinkedin color="#FFF" size={16} />
                </SocialIcon>
              </div>
            </div>
          </div>
          <ContactFormContainer>
            <ContactDot />
            <p>{t("footer.suggestions")}</p>
            <div>
              <div className="d-flex justify-content-between">
                <ContactInput placeholder={t("footer.name")} />
                <ContactInput placeholder={t("footer.email")} />
              </div>
              <ContactTextArea placeholder={t("footer.message")} />
              <ContactSubmit>{t("footer.send")}</ContactSubmit>
            </div>
          </ContactFormContainer>
        </ContactContainer>
      )}

      <div>
        <hr className="m-0" />
        <FooterLogos>
          <img src={EUFundsLogo} alt="EU Funds" />
          <img src={RPLogo} alt="RP" />
          <img src={EULogo} alt="EU" />
        </FooterLogos>
        <hr className="m-0" />
        <FooterInfo>{t("footer.info")}</FooterInfo>
        <FooterAdditional>
          <p className="mb-0">© 2023 by PSNC. All rights reserved.</p>
          <PrivacyPolicy href="#">{t("footer.privacy_policy")}</PrivacyPolicy>
        </FooterAdditional>
      </div>
    </>
  );
};

Footer.propTypes = {
  contact: PropTypes.bool,
};

export default Footer;
