import React from 'react';
import PropTypes from 'prop-types';
import { useLottie } from 'lottie-react';

import { LoadingBackground, LoadingContainer } from '../styles/styled-components/Loading';
import AnimationData from '../../assets/json/nebi_loader.json';
import backgroundAnimationData from '../../assets/json/nebi_background.json';

const Loading = ({ text, background }) => {

  // Settings for lottie animation
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const { View: LoadingAnimation } = useLottie(defaultOptions);

  const renderElements = () => {

    const childrenElement = (
      <LoadingContainer smallMargin={text ? 0 : 1} isBackground={background ? 1 : 0}>
        { LoadingAnimation }
        {
          text && <p className="mt-3">{text}</p>
        }
      </LoadingContainer>
    );

    if (background) {
      return (
        <LoadingBackground animationData={backgroundAnimationData} loop={true}>
          {childrenElement}
        </LoadingBackground>
      );
    } 
    else {
      return (
        childrenElement
      );
    }
  };

  return (
    renderElements()
  );
};

Loading.propTypes = {
  text: PropTypes.string
};

export default Loading;