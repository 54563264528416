import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { RxChevronDown } from 'react-icons/rx';

export const AppContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white };
  border: 2px solid ${({ theme }) => theme.colors.white };
  margin-top: 20px;
  position: relative;
  width: calc(50% - 22px);
  height: 100%;
  border-radius: 12px;
  box-shadow: 0px 0.5px 4px rgba(51, 51, 51, 0.12);
  transition: border .3s;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.green4 };
  }
`;

export const AppBasicContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AppMoreInfoButton = styled.button`
  background-color: ${({ theme }) => theme.colors.white };
  border: none;
  padding: 14px 20px;
  border-radius: 0 12px 12px 0;
`;

export const AppMoreInfoIcon = styled(RxChevronDown)`
  transition: all .3s;
  transform: ${({ rotate }) => rotate ? 'rotateX(180deg)' : 'none'};
`;

export const AppCollapseContainer = styled.div`
  padding: 14px 20px;
`;

export const AppLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black };
  width: 100%;
  padding: 14px 20px;

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black };
  }
`;

