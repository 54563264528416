import styled from 'styled-components';

export const RadioLabel = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  user-select: none;
`;

export const RadioInput = styled.input`
  display: none;
`;

export const RadioCircle = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.gray400};
  position: relative;

  ${RadioInput}:checked + & {
    border: 5px solid ${({ theme }) => theme.colors.green6};
  }
`;

export const RadioLabelText = styled.p`
  margin-bottom: 0;
  margin-left: 10px;
`;
