import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

const theme = {
  colors: {
    black: "#000000",
    blue: "#0041C0",
    green: "#00D42F",
    green3: "#90E683",
    green4: "#69D063",
    green6: "#43B644",
    green7: "#34983D",
    green8: "#297D36",
    gray: "#D9D9D9B3",
    gray2: "#E9EDF5",
    gray3: "#D5DBE5",
    gray400: "#CBD5E0",
    gray6: "#5A6270",
    gray7: "#464F60",
    grayBlue: "#333B4A",
    lightGrey: "#F7F9FC",
    white: "#FFFFFF"
  },
  fontSizes: {
    xxs: '0.625rem',
    xs: '0.75rem',// 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    xl2: '1.5rem', // 22px
    xl3: '1.875rem',
    xl4: '2rem',
    xl5: '2.25rem' //36px
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.any.isRequired
};

export default Theme;