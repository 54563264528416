import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Footer from '../components/Footer';
import AppButton from '../components/AppButton';
import {
  MainSection,
  AnimationContainer, 
  ContentContainer,
  Title,
  SubTitle,
  MainPath,
  MainPathTitle,
  MainPathImg,
  MainPathLink,
  AllApliactionsButton,
  AllAplicationsText,
  ChevronIcon,
  AppsContainer,
  AppsHeader,
} from '../styles/styled-components/Main';
import axiosInstance from '../../main/utils/axios/axiosInstance';
import { __env } from '../../envloader';

import transcriptomicsIcon from '../../assets/svg/icons/transcriptomics.svg';
import proteomicsIcon from '../../assets/svg/icons/proteomics.svg';
import metabolomicsIcon from '../../assets/svg/icons/metabolomics.svg';
import backgroundVideo from '../../assets/video/NEBI_Shiny.mp4';

const Main = () => {

  const { t } = useTranslation();

  const mainPaths = [
    [ "transcriptomics", transcriptomicsIcon ],
    [ "proteomics", proteomicsIcon ], 
    [ "metabolomics", metabolomicsIcon ]
  ];

  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  const [ applications, setApplications ] = useState([]);

  useEffect(() => {
    axiosInstance.get(`${__env.SHINY_PROXY_DOMAIN}api/proxyspec`)
      .then(response => setApplications(response.data.data));
  }, []);

  return (
    <>
      <MainSection>
        <AnimationContainer src={backgroundVideo} autoPlay loop muted />
        <ContentContainer>
          <Title>{t('main.title')}</Title>
          <SubTitle>{t('main.subtitle')}</SubTitle>
          <div className="d-flex justify-content-between w-100 my-5">
            {
              mainPaths.map(([ key, val ]) => (
                <MainPath key={key}>
                  <MainPathTitle>{t(`main.paths.${key}`)}</MainPathTitle>
                  <MainPathImg src={val} />
                  <MainPathLink to={`/path/${key}/0`}>{t('main.start')}</MainPathLink>
                </MainPath>
              ))
            }
          </div>
          <AllApliactionsButton onClick={executeScroll}>
            <ChevronIcon />
            <AllAplicationsText>{t('main.see_all_applications')}</AllAplicationsText>
          </AllApliactionsButton>
        </ContentContainer> 
      </MainSection>
      <AppsContainer ref={myRef}>
        <AppsHeader>
          {t('main.all_applications')}
        </AppsHeader>
        {
          applications?.length !== 0 && applications?.map(({ id }) => <AppButton key={id} id={id} />)
        }
      </AppsContainer>
      <Footer contact />
    </>
  );
};

export default Main;