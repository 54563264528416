import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'reactstrap';

export const TopbarContainer = styled.div`
  height: 6vh;
  width: 100%;
  padding: 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray2 };
`;

export const TopbarNav = styled(Navbar)`
  display: flex;
  justify-content: space-between;
`;

export const NavTopBar = styled(Nav)`
  margin-left: auto;
  margin-right: 24px;
`;

export const NavbarLogo = styled(Link)`
  font-family: "Lato";
  font-size: ${({ theme }) => theme.fontSizes.xl2 };
  color: ${({ theme }) => theme.colors.black };

  &:hover {
    color: ${({ theme }) => theme.colors.black };
    text-decoration: none;
  }
`;

export const LanguageButtonsContainer = styled.div`
  margin-right: 2rem;
  display: flex;
  gap: 8px;
  filter: grayscale(${({ disabled }) => disabled ? 1 : 0});
`;

export const LanguageButton = styled.button`
  background-color: ${({ active, theme }) => active ? theme.colors.green8 : theme.colors.white};
  color: ${({ active, theme }) => active ? theme.colors.white : theme.colors.gray7};
  border: none;
  border-radius: 6px;
  padding: 6px 10px;
`;