import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CustomButton from './CustomButton';

import {
  AppDetailsContainer,
  AppName,
  AppButtonContainer,
  LeftButtons,
  RightButtons,
} from '../styles/styled-components/AppPage';
import {
  PathTreeTitle,
  PathTreeContainer,
  PathTreeImage
} from '../styles/styled-components/PathDescription';
import ExampleExcel from '../../assets/download/exampleExcel.xlsx';
import PathTreeSvgEN from '../../assets/svg/path/pathDefaultEN.svg';
import PathTreeSvgPL from '../../assets/svg/path/pathDefaultPL.svg';

const PathDescription = ({ nextStep, pathId }) => {

  const { t, i18n } = useTranslation();
  const [ selectedPathImg, setSelectedPathImg ] = useState(null);

  useEffect(() => {
    setSelectedPathImg(i18n.resolvedLanguage === "en" ? PathTreeSvgEN : PathTreeSvgPL);
  }, [ i18n.resolvedLanguage ]);

  return (
    <AppDetailsContainer>
      <AppName>{t(`main.paths.${pathId}`)}</AppName>
      <p>{t('path.description')}</p>
      <AppButtonContainer>
        <LeftButtons>
          <a href={ExampleExcel} download={t('path.example_file_name')} target="_blank" rel="noreferrer">
            <CustomButton text={t('path.download')} />
          </a>
        </LeftButtons>
        <RightButtons>
          <CustomButton text={t('path.start')} action={() => nextStep()} main />
        </RightButtons>
      </AppButtonContainer>
      <PathTreeTitle>{t('path.tree')}</PathTreeTitle>
      <PathTreeContainer>
        <PathTreeImage src={selectedPathImg} alt="path tree" />
      </PathTreeContainer>
    </AppDetailsContainer>
  );
};

PathDescription.propTypes = {
  nextStep: PropTypes.func,
  pathId: PropTypes.string
};

export default PathDescription;