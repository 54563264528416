import styled from 'styled-components';

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray2 };
  padding: 2rem;
  border-radius: 1.5rem; 
  width: 400px;
  height: 280px;
`;

export const CardTitle = styled.h5`
  color: ${({ theme }) => theme.colors.gray7};
  font-size: ${({ theme }) => theme.fontSizes.xl };
  font-weight: ${({ theme }) => theme.fontWeights.bold };
  max-width: 50%;
  height: 60px;
`;

export const CardNumber = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.green3};
  font-weight: ${({ theme }) => theme.fontWeights.bold };
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
`;

export const CardInformation = styled.li`
  color: ${({ theme }) => theme.colors.gray7};
`;