import styled, { css } from 'styled-components';
import Lottie from 'lottie-react';

export const LoadingBackground = styled(Lottie)`
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
`;

export const LoadingContainer = styled.div`
  margin: ${({ smallMargin }) => smallMargin ? "2rem auto" : "40vh auto 0 auto" };
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 120px;
  flex-direction: column;
  z-index: 1;
  ${({ isBackground }) => isBackground && css`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
  `}
`;
