import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardTitle,
  CardNumber,
  CardInformation
} from '../styles/styled-components/LandingFunctionCards';

const LandingFunctionCards = () => {

  const { t } = useTranslation();

  const testFunctions = [
    {
      subject: "friendly",
      informations: [
        "choose",
        "step",
        "easy",
        "analysis",
        "download"
      ],
    },
    {
      subject: "analysis",
      informations: [
        "wide",
        "anova",
        "identify"
      ],
    },
    {
      subject: "wizualization",
      informations: [
        "pca",
        "claster",
        "charts",
        "venna"
      ],
    },
  ];

  return (
    <div className="d-flex justify-content-around align-items-center mt-5">
      {
        testFunctions.map((ele, idx) => (
          <Card key={ele.subject}>
            <div className="d-flex justify-content-between align-items-start">
              <CardTitle>{t(`landing.test_functions.cards.${ele.subject}.title`)}</CardTitle>
              <CardNumber>{(idx + 1)}</CardNumber>
            </div>
            <ul>
              {
                ele.informations.map((information) => (
                  <CardInformation key={information}>
                    {t(`landing.test_functions.cards.${ele.subject}.informations.${information}`)}
                  </CardInformation>
                ))
              }
            </ul>
          </Card>
        ))
      }
    </div>
  );
};

export default LandingFunctionCards;