import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { BiDownArrowAlt } from 'react-icons/bi';

export const MainSection = styled.div`
  position: relative;
  height: 700px;
  width: 100%;
`;

export const AnimationContainer = styled.video`
  width: 100vw;
  height: 700px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
`;

export const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  z-index: 1;
`;

export const Title = styled.h1`
  padding-top: 4rem;
  color: ${({ theme }) => theme.colors.grayBlue };
  font-family: "Merriweather";
  font-size: ${({ theme }) => theme.fontSizes.xl5 };
  font-weight: ${({ theme }) => theme.fontWeights.bold };
`;

export const SubTitle = styled.h3`
  color: ${({ theme }) => theme.colors.grayBlue };
  font-size: ${({ theme }) => theme.fontSizes.md };
  font-weight: ${({ theme }) => theme.fontWeights.normal };
  margin: 1rem 10rem;
`;

export const MainPath = styled.div`
  padding: 1.5rem 2rem;
  background-color: ${({ theme }) => theme.colors.white };
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  min-width: 260px;
  border-radius: 24px;
`;

export const MainPathTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.xl };
  &::first-letter {
    text-transform: capitalize;
  }
`;

export const MainPathImg = styled.img`
  max-height: 4rem;
  margin: 20px 0;
`;

export const MainPathLink = styled(Link)`
  background-color: ${({ theme }) => theme.colors.green3 };
  color: ${({ theme }) => theme.colors.grayBlue };
  padding: 10px 42px;
  border-radius: 38px;
`;

export const AllApliactionsButton = styled.button`
  all: unset;
  margin: 54px auto 68px auto;
  background-color: ${({ theme }) => theme.colors.grayBlue };
  padding: 10px 24px;
  border-radius: 38px;
  font-size: 24px;
  display: flex;
  align-items: center;
`;

export const AllAplicationsText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.white};
  margin-left: 16px;
`;

export const ChevronIcon = styled(BiDownArrowAlt)`
  color: ${({ theme }) => theme.colors.white};
`;

export const AppsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white };
  padding: 50px 25vw;
  display: flex;
  flex-wrap: wrap;
  column-gap: 44px;
`;

export const AppsHeader = styled.h2`
  color: ${({ theme }) => theme.colors.green7 };
  font-weight: ${({ theme }) => theme.fontWeights.bold };
  font-size: ${({ theme }) => theme.fontSizes.lg };
  margin-bottom: 36px;
  width: 100%;
`;
