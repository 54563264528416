import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import CustomButton from '../components/CustomButton';
import SingleApp from '../components/SingleApp';
import Loading from '../components/Loading';
import AppButton from '../components/AppButton';
import axiosInstance from '../../main/utils/axios/axiosInstance';
import { __env } from '../../envloader';

import {
  PageContainer,
  AppDetailsContainer,
  AppName,
  AppButtonContainer,
  AppsContainer,
  AppsHeader,
} from '../styles/styled-components/AppPage';

const PathApp = () => {

  const { appId } = useParams();
  const { t } = useTranslation();

  const [ isApp, setIsApp ] = useState(false);
  const [ applications, setApplications ] = useState([]);

  useEffect(() => {
    axiosInstance.get(`${__env.SHINY_PROXY_DOMAIN}api/proxyspec`)
      .then(response => setApplications(response.data.data));
  }, []);

  return (
    <>
      {isApp ?
        <SingleApp />
        :
        <PageContainer className="mt-5">
          <AppDetailsContainer>
            {
              appId ?
                <>
                  <AppName>{t(`apps.titles.${appId}`)}</AppName>
                  <p>{t(`apps.descriptions.${appId}`)}</p>
                  <AppButtonContainer>
                    <CustomButton text={t('path.start')} action={() => setIsApp(true)} main />
                  </AppButtonContainer>
                </>
                :
                <Loading />
            }
          </AppDetailsContainer>
          <AppsHeader>
            {t('main.all_applications')}
          </AppsHeader>
          <AppsContainer>
            {
              applications?.length !== 0 && applications?.map(({ id }) => (
                <AppButton key={id} id={id} />
              ))
            }
          </AppsContainer>
        </PageContainer>
      }
    </>
  );
};

PathApp.propTypes = {
  appName: PropTypes.string,
  setIsApp: PropTypes.func
};

export default PathApp;