import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CustomButton from './CustomButton';
import backendTranslation from '../utils/backendTranslation';

import CustomRadioButton from '../components/CustomRadioButton';
import {
  AppDetailsContainer,
  AppName,
  AppShortDesc,
  AppButtonContainer,
  RadioContainer
} from '../styles/styled-components/AppPage';

const PathForm = ({ id, title, subTitle, description, options, addData, nextStep }) => {

  const { t } = useTranslation();

  const [ selectedOption, setSelectedOption ] = useState('');

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const submit = () => {
    if (selectedOption) {
      addData(selectedOption, id);
      setSelectedOption('');
      nextStep();
    }
  };

  return (
    <AppDetailsContainer>
      <AppName>{backendTranslation(title)}</AppName>
      {
        subTitle && <AppShortDesc>{backendTranslation(subTitle)}</AppShortDesc>
      }
      <p>{backendTranslation(description)}</p>
      <RadioContainer>
        {
          options.map(option => (
            <CustomRadioButton 
              name={id}
              key={`option-${option.value}`}
              value={option.value}
              checked={selectedOption === option.value}
              onChange={handleChange}
              label={backendTranslation(option.name)}
            />
          ))
        }
      </RadioContainer>
      <AppButtonContainer>
        <CustomButton disabled={!selectedOption} text={t('path.next')} main action={submit} />
      </AppButtonContainer>
    </AppDetailsContainer>
  );
};

PathForm.propTypes = {
  id: PropTypes.string,
  title: PropTypes.shape({
    pl: PropTypes.string,
    en: PropTypes.string
  }),
  subTitle: PropTypes.shape({
    pl: PropTypes.string,
    en: PropTypes.string
  }),
  description: PropTypes.shape({
    pl: PropTypes.string,
    en: PropTypes.string
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.shape({
      pl: PropTypes.string,
      en: PropTypes.string
    }),
    value: PropTypes.string
  })),
  addData: PropTypes.func,
  nextStep: PropTypes.func
};

export default PathForm;