import { Map } from 'immutable';
import {
  APPLICATION_IS_RUNNING
} from '../actions/applicationsActions';

const initialState = Map({
  appIsRunning: false
});

export const applicationsReducer = (state = initialState, action) => {
  switch (action.type) {
  case APPLICATION_IS_RUNNING:
    return state.set('appIsRunning', action.payload);
  default:
    return state;
  }
};