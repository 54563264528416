import styled from 'styled-components';

export const PageContainer = styled.div`
  width: ${({ isApp }) => isApp ? "100" : "50"}%;
  height: 100%;
  margin: ${({ isApp }) => isApp ? "0" : "0 auto"};
`;

export const AppDetailsContainer = styled.div`
  box-shadow: 0px 0px 1px rgba(51, 51, 51, 0.4), 0px 0.5px 4px rgba(51, 51, 51, 0.1);
  border-radius: 8px;
  padding: 3rem;
  margin-bottom: 1rem;
`;

export const AppName = styled.h1`
  font-family: 'Merriweather';
  font-size: ${({ theme }) => theme.fontSizes.xl2};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const AppShortDesc = styled.p`
  color: ${({ theme }) => theme.colors.gray7};
`;

export const AppButtonContainer = styled.div`
  margin: 3rem 0 1rem 0;
  display: flex;
  justify-content: space-between;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 26px;
`;

export const AppsHeader = styled.div`
  color: ${({ theme }) => theme.colors.black };
  font-family: "Merriweather";
  font-weight: ${({ theme }) => theme.fontWeights.bold };
  font-size: ${({ theme }) => theme.fontSizes.xl2 };
  margin-bottom: 24px;
  margin-top: 5rem;
  width: 100%;
`;

export const AppsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 44px;
  margin-bottom: 40px;
`;

export const LeftButtons = styled.div`

`;

export const RightButtons = styled.div`
  display: flex;
  gap: 20px;
`;

