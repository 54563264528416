import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CustomButton from './CustomButton';

import {
  PathTopBarContainer,
  BackButton,
  BackButtonText,
} from '../styles/styled-components/PathTopBar';
import BackButtonIcon from '../../assets/svg/icons/backButton.svg';

const PathTopBar = ({ app, nextStep, isAppShow }) => {

  const { t } = useTranslation();

  return (
    <PathTopBarContainer app={app ? 1 : 0}>
      <BackButton to="/">
        <img src={BackButtonIcon} alt="" />
        {
          !(!!app) && <BackButtonText>{t('path.top_bar.back')}</BackButtonText>
        }
      </BackButton>
      {
        !!app && <CustomButton text={t('path.next')} action={nextStep} main disabled={isAppShow ? 0 : 1} />
      }
    </PathTopBarContainer>
  );
};

PathTopBar.propTypes = {
  app: PropTypes.bool,
  nextStep: PropTypes.func,
  isAppShow: PropTypes.bool
};

export default PathTopBar;