import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';

import PathTopBar from '../components/PathTopBar';
import PathApp from '../components/PathApp';
import PathForm from '../components/PathForm';
import PathDescription from '../components/PathDescription';
import SingleApp from '../components/SingleApp';

import {
  PageContainer,
} from '../styles/styled-components/AppPage';
import pathsData from '../../assets/json/paths.json';
import axiosInstance from '../../main/utils/axios/axiosInstance';
import { __env } from '../../envloader';

const PathPage = () => {

  const { pathId, currentStep } = useParams();
  const history = useHistory();

  const [ isApp, setIsApp ] = useState(false);
  const [ proxyAppId, setProxyAppId ] = useState("");
  const [ formData, setFormData ] = useState({});
  const [ path, setPath ] = useState([]);
  const [ isAppShow, setIsAppShow ] = useState(false);

  const nextStep = (stop = true) => {
    // Stop application
    if (stop) {
      axiosInstance.put(`${__env.SHINY_PROXY_DOMAIN}api/${proxyAppId}/status`, {
        "desiredState": "Stopping"
      }).then(() => {
        // Redirect to the next step
        history.push(`/path/${pathId}/${(parseInt(currentStep)+1)}`);
        setIsApp(false);
      });
    }
    else {
      history.push(`/path/${pathId}/${(parseInt(currentStep)+1)}`);
    }
  };

  const addFormData = (data, id) => {
    setFormData(previousData => ({
      ...previousData,
      [id]: data
    }));
  };

  // Find current path
  useEffect(() => {
    const foundedPath = pathsData.find(pathData => pathData.name === pathId);
    if (foundedPath) {
      setPath(foundedPath.value);
    }
    else {
      history.push("/");
    }
  }, [ pathId, history ]);

  // Redirect if step dosen't exist
  useEffect(() => {
    if (path.length !== 0) {
      if (path.length - 1 < currentStep) {
        history.push("/");
      }
    }
  }, [ currentStep, path, history ]);

  const renderAppDescription = () => {
    if (path[currentStep]?.type === "description") {
      return <PathDescription nextStep={() => nextStep(false)} pathId={pathId} />;
    }
  };

  const renderAppContainer = () => {
    if (path[currentStep]?.type === "app") {
      const { id } = path[currentStep];
      if (isApp) {
        return <SingleApp pathAppName={id} setProxyAppId={setProxyAppId} setIsAppShow={setIsAppShow} />;
      }
      return <PathApp appName={id} setIsApp={setIsApp} nextStep={() => nextStep(false)} />;
    }
    return null; 
  };

  const renderFromFormResult = () => {
    if (path[currentStep]?.type === "formResult") {
      const { parameters, options } = path[currentStep];
      // Checking if all parameters are avaliable
      if (parameters.every(parameter => !!formData[parameter])) {
        // Searching for an item that meets all the conditions
        const result = options.find(({ conditions }) => {
          return parameters.every(parameter => formData[parameter] === conditions[parameter]);
        });
        // Render app in the same step
        if (result) {
          if (result.type === "app") {
            if (isApp) {
              return <SingleApp pathAppName={result.id} setProxyAppId={setProxyAppId} />;
            }
            return <PathApp appName={result.id} setIsApp={setIsApp} nextStep={() => nextStep(false)} />;
          }
          else if (result.type === "form"){
            return <PathForm 
              id={result.id}
              title={result.title}
              subtitle={result.subtitle}
              description={result.description}
              options={result.options}
              addData={addFormData}
              nextStep={() => nextStep(false)}
            />;
          }
        }
        else {
          history.push("/");
        }
      }
      else {
        history.push("/");
      }
    }
    return null;
  };

  return (
    <div>
      <PathTopBar app={isApp} nextStep={nextStep} isAppShow={isAppShow} />
      <PageContainer isApp={isApp ? 1 : 0} >
        {renderAppDescription()}
        {renderAppContainer()}
        {
          path[currentStep]?.type === "form" &&
            <PathForm 
              id={path[currentStep].id}
              title={path[currentStep].title}
              subtitle={path[currentStep].subtitle}
              description={path[currentStep].description}
              options={path[currentStep].options}
              addData={addFormData}
              nextStep={() => nextStep(false)}
            />
        }
        {renderFromFormResult()}
      </PageContainer>
    </div>
  );
};

export default PathPage;